.datepicker-custom {
    background: white;
    border-radius: 2%;
}

.react-datepicker__day--selected {
    border: 1px solid #30d4cc !important;
    background: transparent;
    color: black;
}

.react-datepicker__current-month {
    margin-top: 5px;
    margin-bottom: 5px;
}
.react-datepicker__navigation {
    margin-top: 10px;
}
.react-datepicker__day-names {
    margin-top: 5px;
}
.react-datepicker {
    border: none;
}

.react-datepicker__triangle::before {
    border-bottom-color: transparent !important;
}
.react-datepicker__header {
    border-bottom-color: transparent;
}
.react-datepicker-wrapper {
    display: flex;
    border: black;
    height: fit-content;
    align-items: center;
    height: 3rem;
}
.react-datepicker-ignore-onclickoutside::before,
::after {
    border-color: white;
}

.react-datepicker-wrapper {
    border: none !important;
    border-color: white;
}
.react-datepicker__input-container {
    border: none !important;
    width: 90%;
}
.react-datepicker-wrapper input[type="text"] {
    border: 1px solid #e4e4e4 !important;
    border-radius: 4%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 0.5rem;
    width: 100%;
}

.custom-wrapper {
    border: none !important;
}
